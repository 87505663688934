import './App.scss';

function App() {
  return (
    <div className="App">
      <div className='box'>
        <div className="cat">
          <div className="ear ear--left"></div>
          <div className="ear ear--right"></div>
          <div className="face">
            <div className="eye eye--left">
              <div className="eye-pupil"></div>
            </div>
            <div className="eye eye--right">
              <div className="eye-pupil"></div>
            </div>
            <div className="muzzle"></div>
          </div>
        </div>
        <div className="title">
        Vita Tech
        </div>
        <div className='slogan'>
        Advanced crypto technology provider
        </div>
      </div>
    </div>

  );
}

export default App;
